import React from 'react';
import { Tabs, Select, Space } from 'antd'; 
import { Collapse } from 'antd';
import SiteLayout from '@layouts/site-layout';

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}
const email_link = <a href="mailto:support@stepapp.ai">support@stepapp.ai</a>;


const web_link = <a href="https://learn.stepapp.in/login"> STEPapp - Gamified Learning </a>;

const text1 = `
STEPapp is a gamified, personalized, interactive and adaptive learning app which makes learning fun and interesting for school students from grades 6th to 12th. 
STEPapp is an innovative way to learn concepts while ensuring that all the stakeholders (parents, principals, teachers and policymakers) are aware of the learning outcomes at all times. 
Created by a team of 400+ IITians and Doctors after over 5 years of extensive research, STEPapp is a unique gamified learning software product that provides conceptual clarity.
`;

const text2 = `STEPapp is mapped to the school curriculum so it will complement your school studies.
`;

const text3 = `STEPapp is available for students from grades 6 to 12 in regional languages.
`;

const text4 =  `For the best experience, use the web version of 
`;

const text5 = `Yes, STEPapp is free for a few demo chapters but for full access to the syllabus, you will need to pay and upgrade.
`;

const text6 = `STEPapp is developed as a web application and hence is compatible with most devices. Make sure you have the chrome browser installed.
`;

const text7 = `Yes, STEPapp is available in many languages like English, Hindi, Marathi, Gujarati, etc.
`;

const text8 = `
A gamified learning format makes learning fun and engaging with expert-quality content mapped to the curriculum along with elements like challenges, quizzes, and rewards incorporated into the learning process.
`;


const text9 = `
STEPapp is priced at ₹5000 per student ID valid for the respective academic year. There are demo chapters available as well but the full version of the app will be chargeable.
`;

const text10 = `
STEPapp serves multiple boards like CBSE, ICSE & SSC, etc. 
`; 

const text11 = `STEPapp is available for the grades 6th to 12th
`;

const text12 = `
STEPapp provides interactive, gamified content created by a team of 400+ IITians and Doctors that is mapped to the school curriculum. 
You can relearn concepts until mastered, which will enhance your performance in school and competitive exams. 
`;

const text13 = `STEPapp offers many subjects like Physics, Chemistry, Biology, Mathematics, Geography, History, Civics, English, etc. 
`;

const text14 = `After having played at least one chapter along with treasure island, you will be able to see your score/ rank. 
`;

const text15 = `STEPapp is created by a team of 400+ IITians and doctors with an experience of 22 years in the field of education training and mentoring students for competitive exams and the content created is curated in a way that it would be most beneficial to students preparing for these exams.
`;

const text17 = `After the completion of every chapter, certificates are automatically sent to the registered email id and mobile number via SMS.
`;

const text18 = `Yes, you can access the application with different mobile numbers.
`;

const text19 = `Please check that you have entered the correct Mobile Number and that your phone has an active network connection and try again. If your difficulty persists, reach out to us at
`;
const text20 = `We request you to please share a reason for deleting your account and mention your registered mobile number, grade and board at 
`;
const text21 = `STEPapp can be used on most devices, make sure you have chrome browser installed.
`;
const text22 = `To change any of the above, please write to us mentioning your registered mobile number, name, grade & board along with your query at  
`;

const text23 = `No, you have to play from the beginning if you change your current mobile number. In case a user loses/ changes his/her contact no, please write to us at 
`;

const text24 = `Please log out and log back in if your scores are not updating correctly. This can happen if you are using 2 devices simultaneously. 
`;

const text25 = `You have to share your registered mobile number, transaction ID, mode of payment, grade, and board along with your query to us at
`;

const text26 = `There are two types of payment options available for you to purchase STEPapp, Online & offline. You can pay online using your Debit Card/Credit Card/UPI/Mobile wallet from the app or our website. For offline payment, you can draw a cheque in favour of Eduisfun Technologies Pvt. Ltd., with name of student, grade, board & contact Number on the back of the cheque & post it to our office address.
`;

const text27 = `We are sorry to inform you that STEPapp does not have a refund policy for our customers. 
We request you to please write to us with your valuable feedback / grievances and we will get in touch with you. Our email is `;

const text28 = ` In such a case, we recommend that you check with your bank, unsuccessful payments are generally refunded to your account in 3-4 working days.`;


const text29 = `Principals can be aware of the students’ progress at all times. 
Enables principals and teachers to understand the learning outcomes of the student at a glance. 
Acts as a supporting tool for teachers to understand the subject & topic of interest for students as well as the topics & subjects they are poorly performing in.
Acts as a homework app for teachers to ensure that students are doing their homework on time and with good learning efficiency.
 `;

const text30 = ` We have a team dedicated to seamless implementation of STEPapp in schools. The school coordinators will be connected to our implementation team and they will guide them through the entire process step by step. `;

const text31 = ` Students will need internet connection to use STEPapp and the chapters on STEPapp. STEPapp can otherwise be used offline as well. `;

const text32 = ` Principals and teachers will be provided with the credentials for respective dashboards and they will be able to access it from there. 
`; 
 

export default class InfoPage extends React.Component {
    state = {
    tabPosition: 'left',
  }; 

  changeTabPosition = tabPosition => {
    this.setState({ tabPosition });
  };

    render() {
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return (
            <SiteLayout title="FAQ" description={description} image={img}> 

                <div className="comn_bkgimg top_margin_comn home_slider_sec"  
                style={{background:"url('../img/faq_banner.png') no-repeat center center /cover", color:"#fff"}}>  
                <div className="container faq_text">
                        <h3>You Got a Question Get the answer here!</h3>
                        <p>Want to know more about STEPapp? All you need to know about STEPapp is here.</p>
                    </div>
                </div>   
                 
                <div className="faq_sec">
                    <div className="container"> 
                        {/* <div className="faq_search">
                            <span>
                                <input type="text" placeholder="What we can help you with" />
                                <button type="button"><img src="img/magnify.png" alt="magnify image" /></button>
                            </span>
                        </div> */}

                        <Tabs tabPosition={this.state.tabPosition}>
                            <TabPane tab="GETTING STARTED" key="1">
                                <div>
                                    <Collapse defaultActiveKey={['1']} onChange={callback}>
                                        <Panel header="What is STEPapp?" key="1" className="site-collapse-custom-panel">
                                            <p>{text1}</p>
                                        </Panel>
                                        <Panel header="Will it affect my school studies?" key="2" className="site-collapse-custom-panel">
                                        <p>{text2}</p>
                                        </Panel>
                                        <Panel header="Is my grade available on STEPapp?" key="3" className="site-collapse-custom-panel">
                                        <p>{text3}</p>
                                        </Panel>
                                        <Panel header="How can I use STEPapp?" key="4" className="site-collapse-custom-panel">
                                        <p>{text4} {web_link}</p>
                                        </Panel>
                                        <Panel header="Is it free to use?" key="5" className="site-collapse-custom-panel">
                                        <p>{text5}</p>
                                        </Panel>
                                        <Panel header="Is it compatible with my device?" key="6" className="site-collapse-custom-panel">
                                        <p>{text6}</p>
                                        </Panel>
                                        <Panel header="Does STEPapp support multiple languages?" key="7" className="site-collapse-custom-panel">
                                        <p>{text7}</p>
                                        </Panel>
                                        <Panel header="What is a gamified format?" key="8" className="site-collapse-custom-panel">
                                        <p>{text8}</p>
                                        </Panel>
                
                                    </Collapse>
                                </div>
                            </TabPane>  


                            <TabPane tab="PLAYING STEPapp" key="2">
                                <div>
                                <Collapse defaultActiveKey={['1']} onChange={callback}>
                                        <Panel header="Does it have any charges?" key="1" className="site-collapse-custom-panel">
                                        <p>{text9}</p> 
                                        </Panel>
                                        <Panel header="Is my board available?" key="2" className="site-collapse-custom-panel">
                                        <p>{text10}</p>
                                        </Panel>
                                        <Panel header="Is my grade available?" key="3" className="site-collapse-custom-panel">
                                        <p>{text11}</p>
                                        </Panel>
                                        <Panel header="How will it benefit me?" key="4" className="site-collapse-custom-panel">
                                            <p>{text12}</p>
                                        </Panel>
                                        <Panel header="What subjects does STEPapp offer?" key="5" className="site-collapse-custom-panel">
                                        <p>{text13}</p>
                                        </Panel>
                                        <Panel header="When will I be able to see my rank or score on my dashboard? " key="6" className="site-collapse-custom-panel">
                                        <p>{text14}</p>
                                        </Panel>
                                        <Panel header="Will it help with exams preparations like IIT JEE/NEET/SAT/Olympiads etc.?" key="7" className="site-collapse-custom-panel">
                                        <p>{text15}</p>
                                        </Panel>
                                        <Panel header="How do I download a certificate after completing one chapter?" key="9" className="site-collapse-custom-panel">
                                        <p>{text17}</p>
                                        </Panel>
                                        
                                    </Collapse>
                                </div>
                            </TabPane> 


                            <TabPane tab="TECHNICAL ISSUES" key="3">
                                <div>
                                <Collapse defaultActiveKey={['1']} onChange={callback}>
                                        <Panel header="Can I access the same application with multiple mobile numbers?" key="1" className="site-collapse-custom-panel">
                                        <p>{text18}</p>
                                        </Panel>
                                        <Panel header="How to get an OTP?" key="2" className="site-collapse-custom-panel">
                                            <p>{text19}{email_link}</p>
                                        </Panel>
                                        <Panel header="How to delete my account?" key="3" className="site-collapse-custom-panel">
                                        <p>{text20}{email_link}</p>
                                        </Panel>
                                        <Panel header="What devices can STEPapp be used on?" key="4" className="site-collapse-custom-panel">
                                        <p>{text21}</p>
                                        </Panel>
                                        <Panel header="How to change my Username/ Email/ Mobile Number/ Grade/ Board/ Language?" key="5" className="site-collapse-custom-panel">
                                        <p>{text22}{email_link}</p>
                                        </Panel>
                                        <Panel header="Will my progress be saved if I change my mobile number? " key="6" className="site-collapse-custom-panel">
                                        <p>{text23}{email_link}</p>
                                        </Panel>
                                        <Panel header="What if my progress hasn’t been updated correctly?" key="7" className="site-collapse-custom-panel">
                                        <p>{text24}</p>
                                        </Panel> 
                                        <Panel header="In case I have purchased STEPapp premium but the chapters are locked, what should I do?" key="8" className="site-collapse-custom-panel">
                                        <p>{text25}{email_link}</p>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </TabPane>

                             <TabPane tab="PAYMENT ISSUES" key="4">
                                <div>
                                <Collapse defaultActiveKey={['1']} onChange={callback}>
                            
                                        <Panel header="What are the payment options available?" key="1" className="site-collapse-custom-panel">
                                            <p>{text26}</p>
                                        </Panel>
                                       
                                        <Panel header="Can I get a refund?" key="3" className="site-collapse-custom-panel">
                                        <p>{text27}{email_link}</p>
                                        </Panel>

                                        <Panel header="What if my payment is deducted but it is showing unsuccessful on the screen?" key="4" className="site-collapse-custom-panel">
                                        <p>{text28}</p>
                                        </Panel> 
                                    </Collapse>
                                </div>
                            </TabPane>


                            <TabPane tab="STEPapp FOR SCHOOLS" key="5">
                                <div>
                                <Collapse defaultActiveKey={['1']} onChange={callback}>
                                        <Panel header="How can STEPapp benefit schools?" key="1" className="site-collapse-custom-panel">
                                            <p>{text29}</p>
                                        </Panel>
                                        <Panel header="How can your school install STEPapp?" key="2" className="site-collapse-custom-panel">
                                        <p>{text30}</p>
                                        </Panel>
                                        <Panel header="What if internet connectivity is low in school?" key="3" className="site-collapse-custom-panel">
                                        <p>{text31}</p>
                                        </Panel>
                                        <Panel header="How can the school access the Dashboards?" key="5" className="site-collapse-custom-panel">
                                        <p>{text32}</p>
                                        </Panel> 
                                    </Collapse>
                                </div>
                            </TabPane>


                        </Tabs>   
                    </div>
                </div>

                


            </SiteLayout>
        ) 
  }
}
